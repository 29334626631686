<template>
  <div class="case-conent">
    <div class="hd">作业/考试完成情况</div>
    <!--学历案日志列表-->
    <div class="table-content-search">
      <div class="tab">
        <div class="tab-tilte">
          <li @click="HomeObjA()" :class="{active:HomeCur==0}">一课一练</li>
          <li @click="HomeObjB()" :class="{active:HomeCur==1}">单元卷</li>
        </div>
      </div>
      <div class="table">
        <div class="tab-content">

          <div class="rech" v-show="HomeCur==0">
            <span >
              <div class="sear-tab">
              <el-select v-model="ruleFormA.subjectIdA" @change="subjectChangeA" @clear="subjectClearA()" clearable placeholder="学科选择">
            <el-option
                v-for="item in SubjectList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
            </div>
              <span v-if="code==200">
                <el-table
                        border
                        :header-cell-style="{background:'#D1EEFE',color:'#333'}"
                        :data="listDataA"
                >
              <el-table-column prop="sort" label="序号"  header-align="center" width="80"></el-table-column>
              <el-table-column prop="bookName" label="图书名称" header-align="center" ></el-table-column>
              <el-table-column prop="folderName" label="课时名称" header-align="center"></el-table-column>
              <el-table-column prop="AStatus" label="一课一练A卷" header-align="center" width="160"></el-table-column>
              <el-table-column prop="BStatus" label="一课一练B卷" header-align="center" width="160"></el-table-column>
              <el-table-column prop="CStatus" label="一课一练C卷" header-align="center" width="160"></el-table-column>
            </el-table>
              </span>
              <span v-else>
              <div class="kong">
                <img src="../../assets/images/kong.png">
                <p>暂无查询结果！</p>
              </div>
            </span>
            </span>
<!--            <span v-else>-->
<!--              <div class="kong">-->
<!--                <img src="../../assets/images/kong.png">-->
<!--                <p>暂无查询结果！</p>-->
<!--              </div>-->
<!--            </span>-->
          </div>
          <div class="rech" v-show="HomeCur==1">
             <span>
              <div class="sear-tab">
              <el-select v-model="ruleFormB.subjectIdB" @change="subjectChangeB" @clear="subjectClearB()" clearable placeholder="学科选择">
                <el-option
                    v-for="item in SubjectList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
               <span v-if="code==200">
                 <el-table
                         border
                         :header-cell-style="{background:'#D1EEFE',color:'#333'}"
                         :data="listDataB"
                 >
              <el-table-column prop="sort" label="序号"  header-align="center" width="80"></el-table-column>
                   <el-table-column prop="bookName" label="图书名称" header-align="center"></el-table-column>
              <el-table-column prop="folderName" label="单元名称" header-align="center"></el-table-column>
              <el-table-column prop="paperName" label="练习名称" header-align="center"></el-table-column>

              <el-table-column prop="status" label="单元卷完成情况" header-align="center" width="160"></el-table-column>
            </el-table>
               </span>
               <span v-else>
              <div class="kong">
                <img src="../../assets/images/kong.png">
                <p>暂无查询结果！</p>
              </div>
            </span>


            </span>

          </div>
        </div>

      </div>
      <div class="page">
        <div class="pag-box" v-if="HomeCur==0 &&code==200">
          <div class="total-box">
            <span class="total_pag">共<em> {{ total }} </em>条</span>
          </div>
          <el-pagination
              :current-page="currentPage"
              background
              :page-size="pageSize"
              layout="prev, pager, next"
              :total="total"
              @size-change="handleSizeChangeA"
              @current-change="handleCurrentChangeA"
              v-if="total != 0"
          >
            <span class="pag_sise">每页显示</span>
          </el-pagination>
        </div>
        <div class="pag-box" v-if="HomeCur==1 &&code==200">
          <div class="total-box">
            <span class="total_pag">共<em> {{ total }} </em>条</span>
          </div>
          <el-pagination
              :current-page="currentPage"
              background
              :page-size="pageSize"
              layout="prev, pager, next"
              :total="total"
              @size-change="handleSizeChangeB"
              @current-change="handleCurrentChangeB"
              v-if="total != 0"
          >
            <span class="pag_sise">每页显示</span>
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import {queryExerciseListObj,queryUnitExerciseListObj} from '@/api/parent'
export default {
  name: '',
  data() {
    return {
      clickFlag:1,
      HomeCur:0,
      code:null,
      ruleFormA:{
        subjectIdA:null
      },
      ruleFormB:{
        subjectIdB:null
      },
      listDataA:[],
      listDataB:[],
      pageSize:null,
      pageCurrent:null,
      total:null,
    }
  },
  created() {
    this.EducationCase = this.$route.query.EducationCase;
    this.subjectObj()
    this.listObjA()
  },
  watch: {
    // 监视搜索词变化
    $route(){
      this.clickFlag= this.$route.query.clickFlag
    },

  },
  methods: {
    HomeObjA() {
      this.HomeCur=0
      this.ruleFormA.subjectIdA = null
      this.listObjA()
      this.code=''
    },
    HomeObjB() {
      this.HomeCur=1
      this.ruleFormB.subjectIdB = null
      this.listObjB()
      this.code=''
    },
    subjectObj() {
      this.ruleFormA.subjectIdA = this.SubjectList[0].id
    },
    //查询成绩统计列表-一课一练
    listObjA() {
      if(this.ruleFormA.subjectIdA!=null) {
        let params = {
          subjectId: Number(this.ruleFormA.subjectIdA),//学科
          pageCurrent: this.currentPage,//初始页
          pageSize: this.pageSize,//每页的数据
        }
        queryExerciseListObj(params).then((res) => { // eslint-disable-line no-unused-vars
          if(res.code==200) {
            this.listDataA = res.data.records
            this.code = res.code;
            this.pageSize = res.data.size;
            this.currentPage = res.data.current;
            this.total = res.data.total
          } else if(res.code==209) {
            this.code = res.code;
          }
        })
      }else {
        let params = {
          subjectId:null,//学科
          pageCurrent: this.currentPage,//初始页
          pageSize: this.pageSize,//每页的数据
        }
        queryExerciseListObj(params).then((res) => { // eslint-disable-line no-unused-vars
          if(res.code==200) {
            this.listDataA = res.data.records
            this.code = res.code;
            this.pageSize = res.data.size;
            this.currentPage = res.data.current;
            this.total = res.data.total
          } else if(res.code==209) {
            this.code = res.code;
          }
        })
      }

    },
    listObjB() {
      if(this.ruleFormB.subjectIdB!=null) {
        let params = {
          subjectId: Number(this.ruleFormB.subjectIdB),//学科
          pageCurrent: this.currentPage,//初始页
          pageSize: this.pageSize,//每页的数据
        }
        queryUnitExerciseListObj(params).then((res) => { // eslint-disable-line no-unused-vars
          if(res.code==200) {
            this.code = res.code
            this.listDataB = res.data.records
            this.pageSize = res.data.size;
            this.currentPage = res.data.current;
            this.total = res.data.total
          }else if(res.code==209) {
            this.code = res.code
          }

        })
      }else {
        let params = {
          subjectId:null,//学科
          pageCurrent: this.currentPage,//初始页
          pageSize: this.pageSize,//每页的数据
        }
        queryUnitExerciseListObj(params).then((res) => { // eslint-disable-line no-unused-vars
          if(res.code==200) {
            this.code = res.code
            this.listDataB = res.data.records
            this.pageSize = res.data.size;
            this.currentPage = res.data.current;
            this.total = res.data.total
          }else if(res.code==209) {
            this.code = res.code
          }
        })
      }

    },
    //充值订单列表分页
    handleSizeChangeA(val) {
      this.pagesize = val;
      this.listObjA();
    },
    handleCurrentChangeA(val) {
      this.currentPage = val;
      // this.setContextData("currentPage8", this.currentPage);
      // 刷新页面内容
      this.listObjA();
    },
    // 学科
    subjectChangeA($event){
      this.ruleFormA.subjectIdA = $event
      this.listObjA()
    },
    // 清除学科
    subjectClearA() {
      this.ruleFormA.subjectIdA = ''
      this.listObjA()
    },
    // 学科
    subjectChangeB($event){
      this.ruleFormB.subjectIdB = $event
      this.listObjB()
    },
    // 清除学科
    subjectClearB() {
      this.ruleFormB.subjectIdB = ''
      this.listObjB()
    },


  },
  computed:{
    ...mapState([
      'SubjectList',
    ]),
  },
  components: {

  }
}
</script>
<style lang="scss" scoped>
.case-conent {
  margin: 20px auto;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 0.9;

  .hd {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    background: #37AEFF;
    text-align: center;
    color: #fff;
    opacity: 1;
  }
  .tab-tilte{
    width: 100%;
    height: 36px;
    border-bottom: 1px solid #FF9100;
    margin: 10px auto;
    li{
      float: left;
      width: 25%;
      height: 35px;
      line-height: 35px;
      padding: 0px 0;
      text-align: center;
      margin-right: 10px;
      font-size: 15px;
      font-weight: bold;
      background-color:#FFF5E8;
      border: 1px solid #FF9100;
      cursor: pointer;
      border-radius: 6px 6px 0px 0px;
    }
    .active{
      background-color: #FF9100;
      color: #fff;
    }
  }
  .tab-content {
    width: 100%;
    margin: 10px auto;
  }
  .table-content-search {
    width: 96%;
    margin: 10px auto;
    .tab {
      width: 100%;
      height: 40px;
      display: flex;
      margin-bottom: 10px;
      justify-content: flex-end;
      .fromItem {
        margin-left: 10px;

      }
    }
    .table {
      height: 60%;
    }
    .sear-tab {
      width: 100%;
      margin: auto;
      display: flex;
      justify-content: flex-end;
      padding: 5px 0px 10px 0px;
    }
    .page {
      height: 40px;
    }
  }

  .pag-box {
    width: 100%;
    padding-top: 10px;
    box-sizing: content-box;
    justify-content: center;
    display: flex;

    .total-box {
      float: left;
      line-height: 32px;
      margin-right: 10px;
      justify-content: flex-end;
      display: flex;

      .el-pagination {
        white-space: nowrap;
        padding: 2px 5px 2px 0px;
        color: #303133;
        font-weight: 700;

        .el-pager li {
          background: #ccc;
          -webkit-box-sizing: border-box;
        }

        .el-pager li .active {
          background-color: #FF6820;
          color: #FFF;
        }
      }


    }

  }
}
.dialogOrder {
  width: 100%;
  margin: auto auto 20px auto;
  .dialogBody {
    width: 100%;
    line-height: 30px;
    display: flex;
    .lf {
      width: 50%;
      color: #666;
      text-align: right;
    }
    .rg {
      width: 50%;
      color: #000;
      text-align: left;
    }
  }
}
.dialogFooter {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #f2f2f2;
  display: flex;
  justify-content: center;
}
</style>
